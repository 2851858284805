/* You can add global styles to this file, and also import other style files */
@import './assets/fonts/fontawesome/css/all.css';
@import './assets/form-validation.css';

@font-face {
  font-family: 'segoe-ui';
  src: url('./assets/fonts/segoeui.ttf');
  src: url('./assets/fonts/segoeui.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root{
	--yellow: #ffcc04;
	--red: #d31f26;
	--grey-blue: #e8e9f0; /*card Header */
	--grey-light: #f3f0ec; /*Border Grey */
	--grey-medium: #bfbebb; /*bg dark */
	--grey-dark: #444;  /*Black */
	
	font-size: 12px;
	color: var(--grey-dark) !important;
}

.ata-color{
	color: rgb(83,142,213);	
}

.d-color{
	/*color: rgb(194,214,154);*/
	color: rgb(0,176,80);
}

.o-color{
	color: rgb(255,204,0);
	/*color: rgb(255,192,0); */	
}
.ata-group{
	outline: 2px rgba(83,142,213, 0.8) solid;	
}
.pu-group{
	outline: 2px rgba(255,204,0,0.8) solid;	
}
.del-group{
	outline: 2px rgba(0,176,80,0.8) solid;	
}

.txt-red{
	color: var(--red) !important;	
}
:focus:where(input,select, textarea){
	outline: 2px var(--yellow) solid !important;
}
.bb-2{
	border-bottom: 1px var(--grey-medium) solid;	
}
.sticky-box{
	position: sticky !important;
	top: 25px;	
}
.bg-light-blue{
	background: var(--grey-blue) !important;	
}
.bold{
	font-weight: bold;
	font-size: 15px;
}
.form-control:invalid, .form-control.is-invalid, .was-validated,
.form-control.is-valid, .was-validated .form-control:valid{
	background-position-x: right calc(1em + 0.1875rem) !important;
}
.big-price{
	font-size: 35px;	
}

#myTab li a{
	display: block;
	padding: 10px;
	font-size: 20px;
	color: var(--grey-dark);	
	text-decoration: none;
	border-radius: 5px 5px 0 0;
	border: 1px var(--grey-medium) solid;
}

#myTab li a.active{
	color: var(--red) !important;
	background: #fff !important;
	border-bottom: 0px none !important;
}

.item-container>div{
	padding: 10px 0px;
    border-top: 1px solid var(--grey-medium);	
}

.item-container>div::last-child{
	padding-bottom: 0px;
	border-bottom: 1px solid var(--grey-medium);	
}
.table-normal{
	font-size: initial;	
}
.mdtr:after{
	content:'*';
	color: var(--red);
}

.sticky-bo{
	position: sticky;
	top: 35px;	
}

.btn-check{
	padding: 15px;
	font-size: 20px;
	font-weight: bold;
	color: var(--red);
	border: 1px var(--grey-dark) solid;
		
}

.oranged {
    background-color: var(--yellow) !important;
}

.sticky-plus {
	position: sticky;
	top: 500px;	
}

.split {
	height: 100%;
	width: 50%;
	position: fixed;
	/*z-index: 1;*/
	top: 0;
	overflow: hidden;
	padding-top: 20px;
	color: var(--red);
}

.left {
	left: 0;
	background-color: var(--grey-light);
}
  
.right {
	right: 0;
	background-color: var(--grey-blue);
}

.centered {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -40%);
	text-align: center;
}

.myLink {
	text-decoration:none;
	color:var(--red) !important;
	opacity:0.8;
}
.myLink:hover {
	opacity: 1 
}
  
.myLink:hover h1 {
	font-size: 41px;
}

.mySpan {
	border-radius: 15%;
	border: 2px solid var(--red);
	padding: 20px 35px;
	display: inline-block;
	font-size: 105px;
}
 
.myh1 {
	line-height: 90px;
	font-size: 40px;
}
.myP {
	font-size: 15px;
	max-width: 370px;
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;
}
  